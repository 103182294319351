@charset "utf-8";

/*=================================
  汎用クラスの定義
=================================*/
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.notes {
  font-size: 1.4rem;
  display: block;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
.is-flex{
  display: flex;
}
/*共通レイアウト*/
.layoout--2col {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  &__right {
    width: calc(100% - 288px);
    padding: 65px 0 130px 42px;
  }
  .sideNav {
    width: 288px;
  }
}
.widelink__cover {
  img {
     width: 100%;
  }
}
/*link*/
.link--underLine {
  font-size: 1.6rem;
  color: #392301;
  line-height: 1.8;
  background:url(/images/bg_underLine.jpg) bottom repeat-x;
}
/*tab*/
.ui-widget.ui-widget-content {
  border: none;
  border-radius: 0;
  padding: 0;
  background: initial;
}
.ui-tabs .ui-tabs-nav {
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  display: block;
  text-align: center;
  float: none;
}
.ui-tabs .ui-tabs-panel {
  padding: 34px 0;
}
/*tabs ranking*/
#tabsRanking .ui-state-active, #tabsRanking .ui-widget-content .ui-state-active, #tabsRanking .ui-widget-header .ui-state-active, #tabsRanking a.ui-button:active, #tabsRanking .ui-button:active, #tabsRanking .ui-button.ui-state-active:hover {
  background: $accent-color!important;
  &::after {
    border-color: $accent-color transparent transparent transparent!important;
    bottom: -15px!important;
  }
}
#tabsRanking .ui-state-active a {
  color: #fff!important;
}
#tabsRanking .ui-state-default, #tabsRanking .ui-widget-content .ui-state-default, #tabsRanking .ui-widget-header .ui-state-default, #tabsRanking .ui-button, #tabsRanking html .ui-button.ui-state-disabled:hover, #tabsRanking html .ui-button.ui-state-disabled:active {
 background: #fff;
}
#tabsRanking.ui-tabs .ui-tabs-nav li {
  // width: calc(25% - 22.5px);
  border-radius: 2px;
  margin: 0;
  border: 1px solid $accent-color;
  position: relative;
  & + li {
    margin-left: 30px;
  }
  a {
    font-size: 1.5rem;
    font-weight: bold;
    color: $accent-color;
  }
  &:hover {
    background: $accent-color;
    a {
      color: #fff;
    }
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 10px 0 0px;
    border-color: transparent transparent transparent transparent;
    position: absolute;
    bottom: -17px;
    left: -1px;
  }
}
/*tabs category*/
#tabsCategory .ui-state-active, #tabsCategory .ui-widget-content .ui-state-active, #tabsCategory .ui-widget-header .ui-state-active, #tabsCategory a.ui-button:active, #tabsCategory .ui-button:active, #tabsCategory .ui-button.ui-state-active:hover {
  background: #f06595!important;
  border-color: #f06595!important;
}
#tabsCategory .ui-state-active a {
  color: #201a0a!important;
}
#tabsCategory .ui-state-default, #tabsCategory .ui-widget-content .ui-state-default, #tabsCategory .ui-widget-header .ui-state-default, #tabsCategory .ui-button, #tabsCategory html .ui-button.ui-state-disabled:hover, #tabsCategory html .ui-button.ui-state-disabled:active {
 background: #fff;
}
#tabsCategory.ui-tabs .ui-tabs-nav {
  max-width: 785px;
  margin: 0 auto;
  li {
    width: calc(20% - 10.4px);
    border-radius: 2px;
    margin: 0;
    border: 1px solid #201a0a;
    position: relative;
    & + li {
      margin-left: 13px;
    }
    a {
      font-size: 1.4rem;
      font-weight: bold;
      color: #201a0a;
    }
    &:hover {
      background: #f06595;
      a {
        color: #201a0a;
      }
    }
  }
}
/*パンくず*/
.breadcrumb {
  background: #f8f5f6;
  padding: 21px 0 19px;
  font-size: 1.2rem;
  line-height: 1;
  ul {
    display: flex;
    align-items: center;
    li a {
      color: $font-color-base;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    li:first-child a::before {
      content: "";
      width: 13px;
      height: 13px;
      background: url(/images/icon/icon_home.png) center center;
      background-size: contain;
      display: inline-block;
      vertical-align: bottom;
      margin: 0 6px 0 0;
    }
    li + li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: ">";
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
      }
    }
  }
}
/* =================================
  article common
================================= */
a {
  color: $accent-color;
}
p {
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  line-height: 1.8;
  color: $font-color-base;
  margin: 0 0 20px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
th {
  color: $accent-color;
  background: url(/images/bg_table.jpg) repeat left top;
  border: 1px solid #dcd0d3;
  padding: 20px;
  text-align: left;
}
td {
  border: 1px solid #dcd0d3;
  padding: 20px;
  text-align: left;
}
.under {
  .layoout--2col__right {
    padding: 40px 0 60px 42px;
  }
}

/*タイトル　バリエーション　サイズ別*/
  /*吹き出し*/
  .ttl--balloon {
    display: block;
    margin: 0 0 26px;
    span {
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1;
      background: url(/images/bg_border_yellow.jpg) repeat center center;
      position: relative;
      border-radius: 3px;
      text-align: center;
      display: inline-block;
      padding: 12px 25px;
      &::after {
        content: "";
        width: 15px;
        height: 14px;
        background: url(/images/balloonArrow.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%);
      }
    }
  }
  .ttl--underLine {
    font-size: 2.8rem;
    font-weight: bold;
    color: #201a0a;
    position: relative;
    margin: 0 0 56px;
    display: inline-block;
    &:after {
      content: "";
      width: 100%;
      border-bottom: 2px dashed #201a0a;
      display: inline-block;
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ttl--underLine--green {
    font-size: 2.6rem;
    font-weight: bold;
    color: $accent-color;
    position: relative;
    margin: 0 0 56px;
    display: inline-block;
    &:after {
      content: "";
      width: 100%;
      border-bottom: 2px dashed $accent-color;
      display: inline-block;
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  /*下層　タイトルボックス*/
  .secTtlBox {
    text-align: center;
    padding: 137px 0 0;
    background: #fff;
    &.balloonIcon {
      position: relative;
      &::before {
        content: "";
        width: 90px;
        height: 90px;
        background: url(/images/icon/icon_search03.png) no-repeat center center,url(/images/bg_border_yellow.jpg) repeat left top;
        background-size: 30px 30px, auto;
        display: block;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
      }
      &::after {
        content: "";
        width: 17px;
        height: 14px;
        background: url(/images/balloonArrow.png) no-repeat center center;
        background-size: contain;
        display: block;
        position: absolute;
        top: 108px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .ttl--underLine {
      font-size: 3.2rem;
    }
  }
  
/*ボタン*/
.btn--base {
  margin: 33px 0;
  text-align: center;
  a,span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    display: inline-block;
    background: $accent-color;
    padding: 15px 42px;
    border-radius: 2px;
    box-shadow: 0.276px 0.961px 2.85px 0.15px rgba(27, 16, 5, 0.28);
    position: relative;
    &:hover {
      color: #fff;
      opacity: .8;
    }
  }
  &.search {
    & a::before {
      content: "";
      width: 16px;
      height: 16px;
      background: url(/images/icon/icon_search02.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    & span::before {
      content: "";
      width: 16px;
      height: 16px;
      background: url(/images/icon/icon_search02.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  &.arrow {
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 14px;
      border-color: transparent transparent transparent #fff;
      display: inline-block;
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 14px;
      border-color: transparent transparent transparent #fff;
      display: inline-block;
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.back {
    a,span {
      padding-right: 30px;
      padding-left: 55px;
    }
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent #fff transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent #fff transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn--white {
  text-align: center;
  a,span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: $accent-color;
    display: inline-block;
    background: #fff;
    padding: 13px 0;
    border: 2px solid $accent-color;
    border-radius: 2px;
    box-shadow: 0.276px 0.961px 2.85px 0.15px rgba(27, 16, 5, 0.28);
    position: relative;
    &:hover {
      opacity: .8;
    }
  }
  &.back {
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent $accent-color transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent $accent-color transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn--tableIn {
  a {
    font-size: 1.5rem;
    line-height: 1;
    color: #fff;
    display: inline-block;
    background: #534826;
    padding: 8px 37px 8px 17px;
    border-radius: 2px;
    box-shadow: 0.276px 0.961px 2.85px 0.15px rgba(27, 16, 5, 0.28);
    position: relative;
    &:hover {
      color: #fff;
      opacity: .8;
    }
  }
  &.arrow {
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 12px;
      border-color: transparent transparent transparent #fff;
      display: inline-block;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn--2col {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 478px;
  margin: 0 auto;
  > * {
    width: calc(50% - 11px);
    a {
      width: 100%;
    }
  }
  * + * {
    margin: 0 0 0 22px!important;
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
    base
  ================================= */
  html,body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .inner-wrap {
    width: 100%;
  }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .float-l,.float-r {
    float: none;
  } 
  .is-flex{
    display: block;
  }
  .widelink .widelink__cover {
    position: relative;
  }
  /*共通レイアウト*/
  .layoout--2col {
    display: block;
    &__left {
      width: 100%;
      margin: 0;
    }
    &__right {
      width: 100%;
      padding: 0;
    }
    .sideNav {
      width: 100%;
      margin: 0;
    }
  }
  /*link*/
  .link--underLine {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  /*tab*/
  .ui-tabs .ui-tabs-panel {
    padding: 16px 0 0;
  }
  /*tabs ranking*/
  #tabsRanking .ui-state-active, #tabsRanking .ui-widget-content .ui-state-active, #tabsRanking .ui-widget-header .ui-state-active, #tabsRanking a.ui-button:active, #tabsRanking .ui-button:active, #tabsRanking .ui-button.ui-state-active:hover {
    background: $accent-color!important;
    &::after {
      bottom: -11px!important;
    }
  }
  #tabsRanking.ui-tabs .ui-tabs-nav li {
    // width: calc(25% - 9px);
    & + li {
      margin-left: 12px;
    }
    a {
      font-size: 1.4rem;
    }
    &::after {
      border-width: 11px 7px 0 0px;
      bottom: -12px;
    }
  }
  /*tabs category*/
  #tabsCategory.ui-tabs .ui-tabs-nav {
    text-align: center;
    width: 90%;
    li {
      display: inline-block;
      float: none;
      width: initial;
      & + li {
        margin: 0 0 8px 8px;
      }
      a {
        font-size: 1.2rem;
      }
    }
  }
  #tabsCategory.ui-tabs .ui-tabs-panel {
    padding: 16px 10px 0;
  }
  /*パンくず*/
  .breadcrumb {
    padding: 11px 11px 8px;
    font-size: 1.2rem;
    overflow-x: scroll;
    white-space: nowrap;
  }

  /* =================================
    article common
  ================================= */
  p {
    font-size: 1.6rem;
    line-height: 1.6153;
    margin: 0 0 20px;
  }
  th {
    padding: 12px 10px;
  }
  td {
    border: 1px solid #dcd0d3;
    padding: 12px 10px;
  }
  .under {
    .layoout--2col__right {
      padding: 0;
    }
  }
  
  /*タイトル　バリエーション　サイズ別*/
  /*吹き出し*/
  .ttl--balloon {
    margin: 0 0 10px;
    span {
      font-size: 1.5rem;
      padding: 10px 22px;
      &::after {
        content: "";
        width: 9px;
        height: 8px;
        bottom: -7px;
        transform: translateX(-50%);
      }
    }
  }
  .ttl--underLine {
    font-size: 1.9rem;
    margin: 0 0 20px;
    &:after {
      border-bottom: 1px dashed #201a0a;
      bottom: -5px;
    }
  }
  .ttl--underLine--green {
    font-size: 1.9rem;
    margin: 0 0 32px;
    &:after {
      border-bottom: 1px dashed $accent-color;
      bottom: -11px;
    }
  }
  /*下層　タイトルボックス*/
  .secTtlBox {
    padding: 78px 20px 0;
    &.balloonIcon {
      &::before {
        content: "";
        width: 45px;
        height: 45px;
        background: url(/images/icon/icon_search03.png) no-repeat center center,url(/images/bg_border_yellow.jpg) repeat left top;
        background-size: 15px 15px, auto;
        top: 13px;
      }
      &::after {
        content: "";
        width: 8px;
        height: 7px;
        top: 57px;
      }
    }
    .ttl--underLine {
      font-size: 1.8rem;
      line-height: 1.33333;
      span {
        display: block;
      }
    }
  }

/*ボタン*/
.btn--base {
  margin: 18px 0;
  a,span {
    padding: 14px 40px;
  }
  &.search {
    & span::before {
      width: 11px;
      height: 11px;
      margin-right: 8px;
    }
  }
  &.arrow {
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 14px;
      border-color: transparent transparent transparent #fff;
      display: inline-block;
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 0 4px 14px;
      border-color: transparent transparent transparent #fff;
      display: inline-block;
      position: absolute;
      right: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.back {
    a,span {
      padding-right: 30px;
      padding-left: 55px;
    }
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent #fff transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent #fff transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn--white {
  text-align: center;
  a,span {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1;
    color: $accent-color;
    display: inline-block;
    background: #fff;
    padding: 13px 0;
    border: 2px solid $accent-color;
    border-radius: 2px;
    box-shadow: 0.276px 0.961px 2.85px 0.15px rgba(27, 16, 5, 0.28);
    position: relative;
    &:hover {
      opacity: .8;
    }
  }
  &.back {
    & a::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent $accent-color transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
    }
    & span::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 14px 4px 0;
      border-color: transparent $accent-color transparent transparent ;
      display: inline-block;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
  .btn--tableIn {
    a {
      font-size: 1.3rem;
      padding: 9px 31px 9px 15px;
    }
    &.arrow {
      & a::after {
        border-width: 3px 0 3px 10px;
        right: 12px;
      }
    }
  }
  .btn--2col {
    display: block;
    max-width: initial;
    > * {
      width: 100%;
    }
    * + * {
      margin: 10px 0 0 0!important;
    }
  }

}
